import React from "react"
import Layout from "../../component/layout";
import Meta from "../../component/meta"

export default ({location}) =>(
    <React.Fragment>
    <Meta
      title="Roman Guivan: My passion projects"
      lang="en"
      keywords={[]}></Meta>
<Layout language={ location.pathname.split('/')[1] }>
    <h1>My passion-projects </h1>
    <p> 
       Here's a list nonsense I'm galdly wasting my life on
    </p>
    <p><a target="_blank" href="https://colossal-squid.github.io/">yet another try </a> to have a github with all things I ever did</p>
    <p><a href="https://master.d20yxzke9em04a.amplifyapp.com/">Purple Man Simulator</a> My unity stealth game</p>
    <p><a href="https://www.roman-guivan.online/unity/game1/">3d volleyball in unity</a> - because i could.</p>
    
    <h4>archive stuff i need to reupload/rehost</h4>
    <p>
        <a href="http://extreme-wiki.roman-guivan.online"> Extreme sports games list</a> - Remember Tony Hawks Pro Skater games? And maybe SSX games. Ever heard of Surfing game in the same vein? Fingerboard-version of THPS mechanics? I'm still assebling this encyclopedia of mine, the moment this goes to github - I hope its gonna move into being a community project
    </p>
    <p>I had a website in 2016, nameisroman.co, I thought i'll make it selling HTML5 games. I've done a few attempts, but it never took off anywhere really.</p>
    <ul>
      <li><a href="/old-site/eggjump/build/index.htm">The bunny game</a> - Guess it's a bit like doodle jump. Runs too fast because browsers aren't the same as they were in 2016</li>
      <li><a href="/old-site/match-three/build/index.htm">A match-3 with animal faces</a></li>
      <li><a href="/old-site/three-snake/index.htm">a three.js snake game</a> - This is what made me pass an interview once. True story</li>
      <li><a href="/old-site/pacman/index.htm">Pacman with GBA Pokemon sprites</a> - I wanted to learn how to find a path using a flood algorhythm. I did.</li>
      <li><a href="/old-site/picross/index.htm">A picross game</a>but isometric and for some reason SAND-themed? What was i thinking?</li>
    </ul>
</Layout>
</React.Fragment>
)